<template>
    <div class="p-8">
        <h1 class="text-center text-2xl">AWS Config Generator</h1>
        <div class="py-8">
            <h2 class="text-lg">Developer Level:</h2>
            <div class="flex items-center">
                <input
                    v-model="level"
                    id="devops"
                    type="radio"
                    value="devops"
                >
                <label for="devops" class="ml-2 text-sm">DevOps</label>
            </div>
            <div class="flex items-center">
                <input
                    v-model="level"
                    id="senior-product-dev"
                    type="radio"
                    value="senior-product-dev"
                >
                <label for="senior-product-dev" class="ml-2 text-sm">Senior Product Dev</label>
            </div>
            <div class="flex items-center">
                <input
                    v-model="level"
                    id="mid-product-dev"
                    type="radio"
                    value="mid-product-dev"
                >
                <label for="mid-product-dev" class="ml-2 text-sm">Mid Product Dev</label>
            </div>
<!--            <div class="flex items-center">-->
<!--                <input-->
<!--                    v-model="level"-->
<!--                    id="junior-product-dev"-->
<!--                    type="radio"-->
<!--                    value="junior-product-dev"-->
<!--                >-->
<!--                <label for="junior-product-dev" class="ml-2 text-sm">Junior Product Dev</label>-->
<!--            </div>-->
            <div class="flex items-center">
                <input
                    v-model="level"
                    id="senior-web-dev"
                    type="radio"
                    value="senior-web-dev"
                >
                <label for="senior-web-dev" class="ml-2 text-sm">Senior Web Dev</label>
            </div>
            <div class="flex items-center">
                <input
                    v-model="level"
                    id="mid-web-dev"
                    type="radio"
                    value="mid-web-dev"
                >
                <label for="mid-web-dev" class="ml-2 text-sm">Mid Web Dev</label>
            </div>
            <div class="flex items-center">
                <input
                    v-model="level"
                    id="junior-web-dev"
                    type="radio"
                    value="junior-web-dev"
                >
                <label for="junior-web-dev" class="ml-2 text-sm">Junior Web Dev</label>
            </div>
        </div>

        <div class="py-8">
            <h2 class="text-lg">AWS digistorm-identity account username e.g. "john.doe":</h2>
            <input type="text" v-model="awsUsername" class="border-2 rounded border-slate-500">
        </div>

        <div class="grid grid-cols-2 gap-4">

            <div>
                <h3 class="font-bold">~/.aws/config</h3>
                <div class="border rounded p-4 mt-4 overflow-x-scroll">
                    <pre>{{ config }}</pre>
                </div>
            </div>
            <div>
                <h3 class="font-bold">Extend Switch Roles Config</h3>
                <div class="border rounded p-4 mt-4 overflow-x-scroll">
                    <pre>{{ switchRolesConfig }}</pre>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'App',
    data() {
        return {
            level: null,
            awsUsername: null,
        }
    },
    computed: {
        config() {
            if (!this.level || !this.awsUsername) {
                return ''
            }

            const configs = {
                'devops': `[default]
region = ap-southeast-2
output = json

[profile digistorm]
aws_account_id = digistorm-identity

[profile digistorm-master]
role_arn = arn:aws:iam::663854833977:role/AdminRole
mfa_serial = arn:aws:iam::845902745044:mfa/${this.awsUsername}
region = ap-southeast-2
source_profile = digistorm
role_session_name = ${this.awsUsername}_AdminRole_digistorm-master
[profile digistorm-audit]
role_arn = arn:aws:iam::256102336192:role/ReadOnlyRole
mfa_serial = arn:aws:iam::845902745044:mfa/${this.awsUsername}
region = ap-southeast-2
source_profile = digistorm
role_session_name = ${this.awsUsername}_ReadOnlyRole_digistorm-audit
[profile digistorm-identity]
role_arn = arn:aws:iam::845902745044:role/AdminRole
mfa_serial = arn:aws:iam::845902745044:mfa/${this.awsUsername}
region = ap-southeast-2
source_profile = digistorm
role_session_name = ${this.awsUsername}_AdminRole_digistorm-identity
[profile digistorm-shared]
role_arn = arn:aws:iam::623889004570:role/AdminRole
mfa_serial = arn:aws:iam::845902745044:mfa/${this.awsUsername}
region = ap-southeast-2
source_profile = digistorm
role_session_name = ${this.awsUsername}_AdminRole_digistorm-shared
[profile digistorm-prodAU]
role_arn = arn:aws:iam::370205509235:role/AdminRole
mfa_serial = arn:aws:iam::845902745044:mfa/${this.awsUsername}
region = ap-southeast-2
source_profile = digistorm
role_session_name = ${this.awsUsername}_AdminRole_digistorm-prodAU
[profile digistorm-prodEU]
role_arn = arn:aws:iam::072396088633:role/AdminRole
mfa_serial = arn:aws:iam::845902745044:mfa/${this.awsUsername}
region = eu-central-1
source_profile = digistorm
role_session_name = ${this.awsUsername}_AdminRole_digistorm-prodEU
[profile digistorm-prodSG]
role_arn = arn:aws:iam::904524429678:role/AdminRole
mfa_serial = arn:aws:iam::845902745044:mfa/${this.awsUsername}
region = ap-southeast-1
source_profile = digistorm
role_session_name = ${this.awsUsername}_AdminRole_digistorm-prodSG
[profile digistorm-prodUS]
role_arn = arn:aws:iam::003338221996:role/AdminRole
mfa_serial = arn:aws:iam::845902745044:mfa/${this.awsUsername}
region = us-east-2
source_profile = digistorm
role_session_name = ${this.awsUsername}_AdminRole_digistorm-prodUS
[profile digistorm-sapp]
role_arn = arn:aws:iam::308785066327:role/AdminRole
mfa_serial = arn:aws:iam::845902745044:mfa/${this.awsUsername}
region = ap-southeast-2
source_profile = digistorm
role_session_name = ${this.awsUsername}_AdminRole_digistorm-sapp
[profile digistorm-test]
role_arn = arn:aws:iam::512562754772:role/AdminRole
mfa_serial = arn:aws:iam::845902745044:mfa/${this.awsUsername}
region = ap-southeast-2
source_profile = digistorm
role_session_name = ${this.awsUsername}_AdminRole_digistorm-test
[profile digistorm-dev]
role_arn = arn:aws:iam::381972878800:role/AdminRole
mfa_serial = arn:aws:iam::845902745044:mfa/${this.awsUsername}
region = ap-southeast-2
source_profile = digistorm
role_session_name = ${this.awsUsername}_AdminRole_digistorm-dev

[profile digistorm-pci]
aws_account_id = digistorm-pci-identity

[profile digistorm-pci-master]
role_arn = arn:aws:iam::437578459861:role/AdminRole
mfa_serial = arn:aws:iam::793389872216:mfa/${this.awsUsername}
region = ap-southeast-2
source_profile = digistorm-pci
role_session_name = ${this.awsUsername}_AdminRole_digistorm-pci-master
[profile digistorm-pci-audit]
role_arn = arn:aws:iam::514050402440:role/ReadOnlyRole
mfa_serial = arn:aws:iam::793389872216:mfa/${this.awsUsername}
region = ap-southeast-2
source_profile = digistorm-pci
role_session_name = ${this.awsUsername}_ReadOnlyRole_digistorm-pci-audit
[profile digistorm-pci-identity]
role_arn = arn:aws:iam::793389872216:role/AdminRole
mfa_serial = arn:aws:iam::793389872216:mfa/${this.awsUsername}
region = ap-southeast-2
source_profile = digistorm-pci
role_session_name = ${this.awsUsername}_AdminRole_digistorm-pci-identity
[profile digistorm-pci-shared]
role_arn = arn:aws:iam::835609251313:role/AdminRole
mfa_serial = arn:aws:iam::793389872216:mfa/${this.awsUsername}
region = ap-southeast-2
source_profile = digistorm-pci
role_session_name = ${this.awsUsername}_AdminRole_digistorm-pci-shared
[profile digistorm-pci-prod]
role_arn = arn:aws:iam::220239579641:role/AdminRole
mfa_serial = arn:aws:iam::793389872216:mfa/${this.awsUsername}
region = ap-southeast-2
source_profile = digistorm-pci
role_session_name = ${this.awsUsername}_AdminRole_digistorm-pci-prod
[profile digistorm-pci-testing ]
role_arn = arn:aws:iam::856930372990:role/AdminRole
mfa_serial = arn:aws:iam::793389872216:mfa/${this.awsUsername}
region = ap-southeast-2
source_profile = digistorm-pci
role_session_name = ${this.awsUsername}_AdminRole_digistorm-pci-testing
[profile digistorm-pci-development]
role_arn = arn:aws:iam::548481347558:role/AdminRole
mfa_serial = arn:aws:iam::793389872216:mfa/${this.awsUsername}
region = ap-southeast-2
source_profile = digistorm-pci
role_session_name = ${this.awsUsername}_AdminRole_digistorm-pci-development
`,
'senior-product-dev': `[default]
region = ap-southeast-2
output = json

[profile digistorm]
aws_account_id = digistorm-identity

[profile digistorm-prodAU]
role_arn = arn:aws:iam::370205509235:role/DigistormSeniorProductDevRole
mfa_serial = arn:aws:iam::845902745044:mfa/${this.awsUsername}
region = ap-southeast-2
source_profile = digistorm
role_session_name = ${this.awsUsername}_DigistormSeniorProductDevRole_digistorm-prodAU
[profile digistorm-prodEU]
role_arn = arn:aws:iam::072396088633:role/DigistormSeniorProductDevRole
mfa_serial = arn:aws:iam::845902745044:mfa/${this.awsUsername}
region = eu-central-1
source_profile = digistorm
role_session_name = ${this.awsUsername}_DigistormSeniorProductDevRole_digistorm-prodEU
[profile digistorm-prodSG]
role_arn = arn:aws:iam::904524429678:role/DigistormSeniorProductDevRole
mfa_serial = arn:aws:iam::845902745044:mfa/${this.awsUsername}
region = ap-southeast-1
source_profile = digistorm
role_session_name = ${this.awsUsername}_DigistormSeniorProductDevRole_digistorm-prodSG
[profile digistorm-prodUS]
role_arn = arn:aws:iam::003338221996:role/DigistormSeniorProductDevRole
mfa_serial = arn:aws:iam::845902745044:mfa/${this.awsUsername}
region = us-east-2
source_profile = digistorm
role_session_name = ${this.awsUsername}_DigistormSeniorProductDevRole_digistorm-prodUS
[profile digistorm-sapp]
role_arn = arn:aws:iam::003338221996:role/DigistormSeniorProductDevRole
mfa_serial = arn:aws:iam::845902745044:mfa/${this.awsUsername}
region = ap-southeast-2
source_profile = digistorm
role_session_name = ${this.awsUsername}_DigistormSeniorProductDevRole_digistorm-sapp

[profile digistorm-pci]
aws_account_id = digistorm-pci-identity

[profile digistorm-pci-prod]
role_arn = arn:aws:iam::220239579641:role/DigistormSeniorProductDevRole
mfa_serial = arn:aws:iam::793389872216:mfa/${this.awsUsername}
region = ap-southeast-2
source_profile = digistorm-pci
role_session_name = ${this.awsUsername}_DigistormSeniorProductDevRole_digistorm-pci-prod
`,
'mid-product-dev': `[default]
region = ap-southeast-2
output = json

[profile digistorm]
aws_account_id = digistorm-identity

[profile digistorm-prodAU]
role_arn = arn:aws:iam::370205509235:role/DigistormMidProductDevRole
mfa_serial = arn:aws:iam::845902745044:mfa/${this.awsUsername}
region = ap-southeast-2
source_profile = digistorm
role_session_name = ${this.awsUsername}_DigistormMidProductDevRole_digistorm-prodAU
[profile digistorm-prodEU]
role_arn = arn:aws:iam::072396088633:role/DigistormMidProductDevRole
mfa_serial = arn:aws:iam::845902745044:mfa/${this.awsUsername}
region = eu-central-1
source_profile = digistorm
role_session_name = ${this.awsUsername}_DigistormMidProductDevRole_digistorm-prodEU
[profile digistorm-prodSG]
role_arn = arn:aws:iam::904524429678:role/DigistormMidProductDevRole
mfa_serial = arn:aws:iam::845902745044:mfa/${this.awsUsername}
region = ap-southeast-1
source_profile = digistorm
role_session_name = ${this.awsUsername}_DigistormMidProductDevRole_digistorm-prodSG
[profile digistorm-prodUS]
role_arn = arn:aws:iam::003338221996:role/DigistormMidProductDevRole
mfa_serial = arn:aws:iam::845902745044:mfa/${this.awsUsername}
region = us-east-2
source_profile = digistorm
role_session_name = ${this.awsUsername}_DigistormMidProductDevRole_digistorm-prodUS
[profile digistorm-sapp]
role_arn = arn:aws:iam::308785066327:role/DigistormMidProductDevRole
mfa_serial = arn:aws:iam::845902745044:mfa/${this.awsUsername}
region = ap-southeast-2
source_profile = digistorm
role_session_name = ${this.awsUsername}_DigistormMidProductDevRole_digistorm-sapp

[profile digistorm-pci]
aws_account_id = digistorm-pci-identity

[profile digistorm-pci-prod]
role_arn = arn:aws:iam::220239579641:role/DigistormMidProductDevRole
mfa_serial = arn:aws:iam::793389872216:mfa/${this.awsUsername}
region = ap-southeast-2
source_profile = digistorm-pci
role_session_name = ${this.awsUsername}_DigistormMidProductDevRole_digistorm-pci-prod
`,

'senior-web-dev': `[default]
region = ap-southeast-2
output = json

[profile digistorm]
aws_account_id = digistorm-identity

[profile digistorm-prodAU]
role_arn = arn:aws:iam::370205509235:role/DigistormSeniorWebDevRole
mfa_serial = arn:aws:iam::845902745044:mfa/${this.awsUsername}
region = ap-southeast-2
source_profile = digistorm
role_session_name = ${this.awsUsername}_DigistormSeniorWebDevRole_digistorm-prodAU
[profile digistorm-prodEU]
role_arn = arn:aws:iam::072396088633:role/DigistormSeniorWebDevRole
mfa_serial = arn:aws:iam::845902745044:mfa/${this.awsUsername}
region = eu-central-1
source_profile = digistorm
role_session_name = ${this.awsUsername}_DigistormSeniorWebDevRole_digistorm-prodEU
[profile digistorm-prodSG]
role_arn = arn:aws:iam::904524429678:role/DigistormSeniorWebDevRole
mfa_serial = arn:aws:iam::845902745044:mfa/${this.awsUsername}
region = ap-southeast-1
source_profile = digistorm
role_session_name = ${this.awsUsername}_DigistormSeniorWebDevRole_digistorm-prodSG
[profile digistorm-prodUS]
role_arn = arn:aws:iam::003338221996:role/DigistormSeniorWebDevRole
mfa_serial = arn:aws:iam::845902745044:mfa/${this.awsUsername}
region = us-east-2
source_profile = digistorm
role_session_name = ${this.awsUsername}_DigistormSeniorWebDevRole_digistorm-prodUS
`,

'mid-web-dev': `[default]
region = ap-southeast-2
output = json

[profile digistorm]
aws_account_id = digistorm-identity

[profile digistorm-prodAU]
role_arn = arn:aws:iam::370205509235:role/DigistormMidWebDevRole
mfa_serial = arn:aws:iam::845902745044:mfa/${this.awsUsername}
region = ap-southeast-2
source_profile = digistorm
role_session_name = ${this.awsUsername}_DigistormMidWebDevRole_digistorm-prodAU
[profile digistorm-prodEU]
role_arn = arn:aws:iam::072396088633:role/DigistormMidWebDevRole
mfa_serial = arn:aws:iam::845902745044:mfa/${this.awsUsername}
region = eu-central-1
source_profile = digistorm
role_session_name = ${this.awsUsername}_DigistormMidWebDevRole_digistorm-prodEU
[profile digistorm-prodSG]
role_arn = arn:aws:iam::904524429678:role/DigistormMidWebDevRole
mfa_serial = arn:aws:iam::845902745044:mfa/${this.awsUsername}
region = ap-southeast-1
source_profile = digistorm
role_session_name = ${this.awsUsername}_DigistormMidWebDevRole_digistorm-prodSG
[profile digistorm-prodUS]
role_arn = arn:aws:iam::003338221996:role/DigistormMidWebDevRole
mfa_serial = arn:aws:iam::845902745044:mfa/${this.awsUsername}
region = us-east-2
source_profile = digistorm
role_session_name = ${this.awsUsername}_DigistormMidWebDevRole_digistorm-prodUS
`,
'junior-web-dev': `[default]
region = ap-southeast-2
output = json

[profile digistorm]
aws_account_id = digistorm-identity

[profile digistorm-prodAU]
role_arn = arn:aws:iam::370205509235:role/DigistormJuniorWebDevRole
mfa_serial = arn:aws:iam::845902745044:mfa/${this.awsUsername}
region = ap-southeast-2
source_profile = digistorm
role_session_name = ${this.awsUsername}_DigistormJuniorWebDevRole_digistorm-prodAU
[profile digistorm-prodEU]
role_arn = arn:aws:iam::072396088633:role/DigistormJuniorWebDevRole
mfa_serial = arn:aws:iam::845902745044:mfa/${this.awsUsername}
region = eu-central-1
source_profile = digistorm
role_session_name = ${this.awsUsername}_DigistormJuniorWebDevRole_digistorm-prodEU
[profile digistorm-prodSG]
role_arn = arn:aws:iam::904524429678:role/DigistormJuniorWebDevRole
mfa_serial = arn:aws:iam::845902745044:mfa/${this.awsUsername}
region = ap-southeast-1
source_profile = digistorm
role_session_name = ${this.awsUsername}_DigistormJuniorWebDevRole_digistorm-prodSG
[profile digistorm-prodUS]
role_arn = arn:aws:iam::003338221996:role/DigistormJuniorWebDevRole
mfa_serial = arn:aws:iam::845902745044:mfa/${this.awsUsername}
region = us-east-2
source_profile = digistorm
role_session_name = ${this.awsUsername}_DigistormJuniorWebDevRole_digistorm-prodUS
`,
            }

            return configs[this.level]
        },
        switchRolesConfig() {
            if (!this.level) {
                return ''
            }

            const configs = {
                'devops': `# Digistorm ---------------------------------------------------------
[profile digistorm]
aws_account_id = 845902745044
aws_account_alias = digistorm-identity

[profile digistorm-master]
role_arn = arn:aws:iam::663854833977:role/AdminRole
source_profile = digistorm
[profile digistorm-audit]
role_arn = arn:aws:iam::256102336192:role/ReadOnlyRole
source_profile = digistorm
[profile digistorm-identity]
role_arn = arn:aws:iam::845902745044:role/AdminRole
source_profile = digistorm
[profile digistorm-shared]
role_arn = arn:aws:iam::623889004570:role/AdminRole
source_profile = digistorm
[profile digistorm-prodAU]
role_arn = arn:aws:iam::370205509235:role/AdminRole
source_profile = digistorm
[profile digistorm-prodEU]
role_arn = arn:aws:iam::072396088633:role/AdminRole
source_profile = digistorm
[profile digistorm-prodSG]
role_arn = arn:aws:iam::904524429678:role/AdminRole
source_profile = digistorm
[profile digistorm-prodUS]
role_arn = arn:aws:iam::003338221996:role/AdminRole
source_profile = digistorm
[profile digistorm-sapp]
role_arn = arn:aws:iam::308785066327:role/AdminRole
source_profile = digistorm
[profile digistorm-test]
role_arn = arn:aws:iam::512562754772:role/AdminRole
source_profile = digistorm
[profile digistorm-dev]
role_arn = arn:aws:iam::381972878800:role/AdminRole
source_profile = digistorm

[profile digistorm-pci]
aws_account_id = digistorm-pci-identity

[profile digistorm-pci-master]
role_arn = arn:aws:iam::437578459861:role/AdminRole
source_profile = digistorm-pci
[profile digistorm-pci-audit]
role_arn = arn:aws:iam::514050402440:role/ReadOnlyRole
source_profile = digistorm-pci
[profile digistorm-pci-identity]
role_arn = arn:aws:iam::793389872216:role/AdminRole
source_profile = digistorm-pci
[profile digistorm-pci-shared]
role_arn = arn:aws:iam::835609251313:role/AdminRole
source_profile = digistorm-pci
[profile digistorm-pci-prod]
role_arn = arn:aws:iam::220239579641:role/AdminRole
source_profile = digistorm-pci
[profile digistorm-pci-testing ]
role_arn = arn:aws:iam::856930372990:role/AdminRole
source_profile = digistorm-pci
[profile digistorm-pci-development]
role_arn = arn:aws:iam::548481347558:role/AdminRole
source_profile = digistorm-pci`,
                'senior-product-dev': `# Digistorm ---------------------------------------------------------
[profile digistorm]
aws_account_id = 845902745044
aws_account_alias = digistorm-identity

[profile digistorm-prodAU]
role_arn = arn:aws:iam::370205509235:role/DigistormSeniorProductDevRole
source_profile = digistorm
[profile digistorm-prodEU]
role_arn = arn:aws:iam::072396088633:role/DigistormSeniorProductDevRole
source_profile = digistorm
[profile digistorm-prodSG]
role_arn = arn:aws:iam::904524429678:role/DigistormSeniorProductDevRole
source_profile = digistorm
[profile digistorm-prodUS]
role_arn = arn:aws:iam::003338221996:role/DigistormSeniorProductDevRole
source_profile = digistorm
[profile digistorm-sapp]
role_arn = arn:aws:iam::308785066327:role/DigistormSeniorProductDevRole
source_profile = digistorm

[profile digistorm-pci]
aws_account_id = digistorm-pci-identity

[profile digistorm-pci-prod]
role_arn = arn:aws:iam::220239579641:role/DigistormSeniorProductDevRole
source_profile = digistorm-pci`,
                'mid-product-dev': `# Digistorm ---------------------------------------------------------
[profile digistorm]
aws_account_id = 845902745044
aws_account_alias = digistorm-identity

[profile digistorm-prodAU]
role_arn = arn:aws:iam::370205509235:role/DigistormMidProductDevRole
source_profile = digistorm
[profile digistorm-prodEU]
role_arn = arn:aws:iam::072396088633:role/DigistormMidProductDevRole
source_profile = digistorm
[profile digistorm-prodSG]
role_arn = arn:aws:iam::904524429678:role/DigistormMidProductDevRole
source_profile = digistorm
[profile digistorm-prodUS]
role_arn = arn:aws:iam::003338221996:role/DigistormMidProductDevRole
source_profile = digistorm
[profile digistorm-sapp]
role_arn = arn:aws:iam::308785066327:role/DigistormMidProductDevRole
source_profile = digistorm

[profile digistorm-pci]
aws_account_id = digistorm-pci-identity

[profile digistorm-pci-prod]
role_arn = arn:aws:iam::220239579641:role/DigistormMidProductDevRole
source_profile = digistorm-pci`,
                'junior-product-dev': `# Digistorm ---------------------------------------------------------
[profile digistorm]
aws_account_id = 845902745044
aws_account_alias = digistorm-identity

[profile digistorm-prodAU]
role_arn = arn:aws:iam::370205509235:role/DigistormJuniorProductDevRole
source_profile = digistorm
[profile digistorm-prodEU]
role_arn = arn:aws:iam::072396088633:role/DigistormJuniorProductDevRole
source_profile = digistorm
[profile digistorm-prodSG]
role_arn = arn:aws:iam::904524429678:role/DigistormJuniorProductDevRole
source_profile = digistorm
[profile digistorm-prodUS]
role_arn = arn:aws:iam::003338221996:role/DigistormJuniorProductDevRole
source_profile = digistorm
[profile digistorm-sapp]
role_arn = arn:aws:iam::308785066327:role/DigistormJuniorProductDevRole
source_profile = digistorm

[profile digistorm-pci]
aws_account_id = digistorm-pci-identity

[profile digistorm-pci-prod]
role_arn = arn:aws:iam::220239579641:role/DigistormJuniorProductDevRole
source_profile = digistorm-pci`,
                'senior-web-dev': `# Digistorm ---------------------------------------------------------
[profile digistorm]
aws_account_id = 845902745044
aws_account_alias = digistorm-identity

[profile digistorm-prodAU]
role_arn = arn:aws:iam::370205509235:role/DigistormSeniorWebDevRole
source_profile = digistorm
[profile digistorm-prodEU]
role_arn = arn:aws:iam::072396088633:role/DigistormSeniorWebDevRole
source_profile = digistorm
[profile digistorm-prodSG]
role_arn = arn:aws:iam::904524429678:role/DigistormSeniorWebDevRole
source_profile = digistorm
[profile digistorm-prodUS]
role_arn = arn:aws:iam::003338221996:role/DigistormSeniorWebDevRole
source_profile = digistorm`,
                'mid-web-dev': `# Digistorm ---------------------------------------------------------
[profile digistorm]
aws_account_id = 845902745044
aws_account_alias = digistorm-identity

[profile digistorm-prodAU]
role_arn = arn:aws:iam::370205509235:role/DigistormMidWebDevRole
source_profile = digistorm
[profile digistorm-prodEU]
role_arn = arn:aws:iam::072396088633:role/DigistormMidWebDevRole
source_profile = digistorm
[profile digistorm-prodSG]
role_arn = arn:aws:iam::904524429678:role/DigistormMidWebDevRole
source_profile = digistorm
[profile digistorm-prodUS]
role_arn = arn:aws:iam::003338221996:role/DigistormMidWebDevRole
source_profile = digistorm`,
                'junior-web-dev': `# Digistorm ---------------------------------------------------------
[profile digistorm]
aws_account_id = 845902745044
aws_account_alias = digistorm-identity

[profile digistorm-prodAU]
role_arn = arn:aws:iam::370205509235:role/DigistormJuniorWebDevRole
source_profile = digistorm
[profile digistorm-prodEU]
role_arn = arn:aws:iam::072396088633:role/DigistormJuniorWebDevRole
source_profile = digistorm
[profile digistorm-prodSG]
role_arn = arn:aws:iam::904524429678:role/DigistormJuniorWebDevRole
source_profile = digistorm
[profile digistorm-prodUS]
role_arn = arn:aws:iam::003338221996:role/DigistormJuniorWebDevRole
source_profile = digistorm`,
            }

            return configs[this.level]
        },
    },
}
</script>

